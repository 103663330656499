import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withApps,
  withMessages,
} from '../../services/AthomApi';
import Page from '../Page';
import Card from '../Card';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';

import {
  List,
  ListItem,
} from '@material-ui/core';

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import ExternalLink from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import BuildDiff from './components/BuildDiff/BuildDiff';

import iconVerified from '../App/icons/verified.svg'

import './style.css';

const REFRESH_AFTER_SUBMISSION_TIMEOUT = 1500;

class PageAppsAppBuild extends Component {

  state = {
    app: undefined,
    build: undefined,
    liveBuild: undefined,
    submissionComments: null,
    message: '',
    autoRelease: true,
  }

  componentDidMount() {
    this.handleRefreshApps();
    this.handleRefreshBuild();
    this.handleRefreshSubmissionComments();
  }

  handleRefreshApps = () => {
    const { appId } = this.props.match.params;

    this.props.callApps('getApp', { appId }).then(app => {
      this.setState({ app });

      if (app.liveBuild) {
        this.liveBuildId = app.liveBuild.id;
        this.handleRefreshLiveBuild();
      }
    }).catch(this.props.handleError);

  }

  handleRefreshBuild = () => {
    const { appId, buildId } = this.props.match.params;

    this.props.callApps('getBuild', {
      appId,
      buildId,
    }).then(build => {
      this.setState({ build });
    }).catch(this.props.handleError);
  }

  handleRefreshLiveBuild = () => {
    const { appId } = this.props.match.params;

    if (!this.liveBuildId) return;

    this.props.callApps('getBuild', {
      appId,
      buildId: String(this.liveBuildId),
    }).then(liveBuild => {
      this.setState({ liveBuild });
    }).catch(this.props.handleError);
  }

  handleRefreshSubmissionComments = () => {
    const { appId, buildId } = this.props.match.params;
    this.props.callApps('getSubmissionComments', {
      appId,
      buildId,
    }).then(submissionComments => {
      this.setState({ submissionComments })
    }).catch(this.props.handleError);
  }

  handlePublishToTest = () => {
    const { appId, buildId } = this.props.match.params;

    this.props.callApps('updateBuildChannel', {
      appId,
      buildId,
      channel: 'test',
    }).then(() => {
      this.handleRefreshBuild();
    }).catch(this.props.handleError);
  }

  handlePublishToLive = () => {
    const { appId, buildId } = this.props.match.params;

    this.props.callApps('updateBuildChannel', {
      appId,
      buildId,
      channel: 'live',
    }).then(() => {
      this.handleRefreshBuild();
    }).catch(this.props.handleError);
  }

  handleCreateSubmission = () => {
    const { appId, buildId } = this.props.match.params;
    const { autoRelease } = this.state;

    this.props.callApps('createSubmission', {
      appId,
      buildId,
      autoRelease,
    }).then(() => {
      this.handleRefreshBuild();
      // The backend may auto-approve this build but this takes a short amount of time
      // To ensure we show the most up to date state we refresh after a short timeout
      setTimeout(() => this.handleRefreshBuild(), REFRESH_AFTER_SUBMISSION_TIMEOUT);
    }).catch(this.props.handleError);
  }

  handleApproveSubmission = () => {
    const { appId, buildId } = this.props.match.params;

    this.props.callApps('approveSubmission', {
      appId,
      buildId,
    }).then(() => {
      this.handleRefreshBuild();
    }).catch(this.props.handleError);
  }

  handleRejectSubmission = () => {
    const { appId, buildId } = this.props.match.params;

    this.props.callApps('rejectSubmission', {
      appId,
      buildId,
    }).then(() => {
      this.handleRefreshBuild();
    }).catch(this.props.handleError);
  }

  handleRetractSubmission = () => {
    const { appId, buildId } = this.props.match.params;

    this.props.callApps('retractSubmission', {
      appId,
      buildId,
    }).then(() => {
      this.handleRefreshBuild();
    }).catch(this.props.handleError);
  }

  handleSubmitComment = () => {
    const { appId, buildId } = this.props.match.params;
    const { message } = this.state;

    this.props.callApps('createSubmissionComment', {
      appId,
      buildId,
      text: message,
    }).then(() => {
      this.handleRefreshSubmissionComments();
      this.setState({
        message: '',
      });
    }).catch(this.props.handleError);
  }

  handleChangeAutoRelease = (e, value) => {
    this.setState({
      autoRelease: value,
    });
  }

  _addToMessage(str) {

    let { message, app, build } = this.state;
    if (message === '')
      message += `Dear ${app.author.name},\n\nThank you for submitting your app ${build.name.en} for review.\n\n`;

    message = message + str;

    this.setState({ message });
  }

  render() {
    const {
      appId,
      buildId,
    } = this.props.match.params;

    const {
      app,
      build,
      liveBuild,
      submissionComments,
    } = this.state;

    // TODO: Change to scope: apps.review
    const userIsReviewer = this.props.user && this.props.user.roleIds.includes('app_reviewer');

    let activeStep;
    const buttons = [];

    const isState = (state) => {
      return build && build.state === state;
    }

    if (app && build) {

      if (isState('draft')) {
        activeStep = 0;
      } else if (isState('test')) {
        activeStep = 1;
      } else if (isState('in_review') || isState('reviewed_approved') || isState('reviewed_rejected')) {
        activeStep = 2;
      } else if (isState('live') || isState('superseded')) {
        activeStep = 3;
      }

      if (isState('draft')) {
        buttons.push((
          <Button
            key={0}
            variant="contained"
            color="primary"
            onClick={this.handlePublishToTest}
          >
            Publish to Test
          </Button>
        ));
      } else if (isState('test')) {
        buttons.push((
          <Button
            key={0}
            variant="contained"
            color="primary"
            onClick={this.handleCreateSubmission}
          >
            {`Submit for Certification`}
          </Button>
        ));
        buttons.push(<br />
        );
        buttons.push(
          <FormControlLabel
            label="Automatically Publish after Approval"
            control={
              <Checkbox
                checked={this.state.autoRelease}
                onChange={this.handleChangeAutoRelease}
                color="primary"
              />
            }
          />
        )
      } else if (isState('in_review')) {

        if (userIsReviewer) {

          // Reject
          buttons.push((
            <Button
              key={0}
              color="secondary"
              variant="contained"
              onClick={this.handleRejectSubmission}
            >
              Reject
            </Button>
          ));

          // Approve
          buttons.push((
            <Button
              key={1}
              color="primary"
              variant="contained"
              onClick={this.handleApproveSubmission}
            >
              Approve
            </Button>
          ));

        }

        buttons.push((
          <Button
            key={2}
            color="primary"
            variant="contained"
            onClick={this.handleRetractSubmission}
          >
            Retract Submission
          </Button>
        ));

        // Diff
        if (build && liveBuild) {
        }

      }

      // Reviewed & Rejected
      else if (isState('reviewed_rejected')) {

      }

      // Reviewed & Approved
      else if (isState('reviewed_approved')) {

        // Publish to Live
        buttons.push((
          <Button
            key={1}
            color="primary"
            variant="contained"
            onClick={this.handlePublishToLive}
          >
            Publish to Live
          </Button>
        ));
      }
    }

    const ReviewerTools = (
      <List>
        <ListItem dense={true}>
          <Button
            size={"small"} variant={"outlined"}
            onClick={() => this._addToMessage(`To continue the certification process, we require additional information from you.\n\nQUESTION\n\nWhen we have received a response from you, the certification process will continue.`)}
          >Ask question</Button>
        </ListItem>
        <ListItem dense={true}>
          <Button
            size={"small"} variant={"outlined"}
            onClick={() => this._addToMessage(`Unfortunately, your app has not passed the certification process, due to the following:\n\n`)}
          >Rejected</Button>
        </ListItem>
        <ListItem dense={true}>
          <Button
            size={"small"} variant={"outlined"}
            onClick={() => this._addToMessage(`It looks great, so we have gone ahead and approved your submission.\n\nHowever to make your App Store page even better, we have a (few) small point(s) of feedback:\n\n— \n\nConsider implementing the given feedback in your next update, to make the most out of your App Store page.`)}
          >Feedback</Button>
        </ListItem>
        <ListItem dense={true}>
          <Button
            size={"small"} variant={"outlined"}
            onClick={() => this._addToMessage(`—  Your app’s description is not up to our standards. The description is shown above your readme in the App Store, therefore repetition should be avoided. Please sell your app’s purpose in one short sentence. Consider using “EXAMPLE”.`)}
          >Feedback - Description</Button>
        </ListItem>
        <ListItem dense={true}>
          <Button
            size={"small"} variant={"outlined"}
            onClick={() => this._addToMessage(`— Your app's images are unsharp. Please make sure to include high-resolution images of 500px by 350px and 250px by 175px.\n\n`)}
          >Rejected - Unsharp Images</Button>
        </ListItem>
        <ListItem dense={true}>
          <Button
            size={"small"} variant={"outlined"}
            onClick={() => this._addToMessage(`— Your app's readme contains markdown formatting. Please remove the markdown and make sure that your app's readme only contains plain-text content.\n\n`)}
          >Rejected - Markdown</Button>
        </ListItem>
        <ListItem dense={true}>
          <Button
            size={"small"} variant={"outlined"}
            onClick={() => this._addToMessage(`— Your app's images contain a white background. Please make sure that the background color extends to all edged to ensure the best possible viewing experience.\n\n`)}
          >Rejected - Image too white</Button>
        </ListItem>
        <ListItem dense={true}>
          <Button
            size={"small"} variant={"outlined"}
            onClick={() => this._addToMessage(`— Your app's brand color should reflect the color of the brand.\n\n`)}
          >Rejected - Brand color</Button>
        </ListItem>
        <ListItem dense={true}>
          <Button
            size={"small"} variant={"outlined"}
            onClick={() => this._addToMessage(`— Your app's readme contains URLs that should only be present in your app's JSON. For example, these can be issue, support or homepage URLs.\n\n`)}
          >Rejected - Duplicate URLs</Button>
        </ListItem>
        <ListItem dense={true}>
          <Button
            size={"small"} variant={"outlined"}
            onClick={() => this._addToMessage(`— Your app's readme contains a changelog. Please use the provided changelog functionality of the Homey App Store.\n\n`)}
          >Rejected - Changelog</Button>
        </ListItem>
        <ListItem dense={true}>
          <Button
            size={"small"} variant={"outlined"}
            onClick={() => this._addToMessage(`Once you have solved these issues, you can submit a new version for certification again.`)}
          >Rejected - end</Button>
        </ListItem>
      </List>
    );

    const SubmissionComments = (
      <div className="SubmissionComments">
        {submissionComments && submissionComments.map((comment, i) => (
          <div key={i} className={`SubmissionCommentWrap ${comment.role === 'reviewer' ? 'Left' : 'Right'}`}>
            <div className="SubmissionComment">
              <div className="Date"><TimeAgo date={comment.createdAt} /></div>
              <div className="AuthorName">{comment.role === 'reviewer' ? 'Reviewer' : 'You'}</div>
              <div className="Text">{comment.text}</div>
            </div>
          </div>
        ))}

        {(activeStep === 2 || activeStep === 3) && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="ComposeMessage"
          >

            <Grid item xs={10}>
              <TextField
                inputProps={{
                  maxLength: 10000
                }}
                multiline={true}
                value={this.state.message}
                margin="normal"
                label="Your message"
                type="textarea"
                fullWidth
                onChange={e => {
                  this.setState({
                    message: e.target.value
                  });
                }}
                disabled={!userIsReviewer && !isState('in_review')}
                style={{
                  fontWeight: 400
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSubmitComment}
                disabled={!userIsReviewer && !isState('in_review')}
              >
                Send &nbsp; <SendIcon fontSize="small" />
              </Button>
            </Grid>
          </Grid>
        )}


      </div>
    );

    return (
      <Page className="AppsAppBuild" cards>

        {app && (
          <Card>
            <Typography variant="h5" color="textPrimary">
              <Link className="AppsHeaderLink" to={`/apps`}>My Apps</Link>
              <NavigateNextIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
              <Link className="AppsHeaderLink" to={`/apps/app/${appId}`}>{app.id}</Link>
              <NavigateNextIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
              <Link className="AppsHeaderLink" to={`/apps/app/${appId}/build/${buildId}`}>Build {buildId} {build && `(v${build.version})`}</Link>
            </Typography>
          </Card>
        )}

        {app && app.author && (
          <Card>
            <Typography variant="h5" color="textPrimary">
              App Author
            </Typography>

            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <ExternalLink href={`https://homey.app/author/${app.author.userId}`}>
                  <Typography variant="body1" color="textPrimary">
                    {app.author.name}
                  </Typography>
                </ExternalLink>
              </Box>
              {app.author.verified && (
                <Box display="flex" alignItems="center">
                  <img src={iconVerified} style={{ height: '1em', marginLeft: '5px', marginBottom: '-2px' }} />
                </Box>
              )}
            </Box>
          </Card>
        )}

        {app && build && (
          <Card title="App Submission">
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step>
                <StepLabel>Draft</StepLabel>
              </Step>
              <Step>
                <StepLabel>Test</StepLabel>
              </Step>
              <Step>
                <StepLabel error={isState('reviewed_rejected')}>Certification</StepLabel>
              </Step>
              <Step>
                <StepLabel>Live</StepLabel>
              </Step>
            </Stepper>

            {isState('draft') && (
              <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
                Your app is currently in draft. Release it to Test to start testing.
              </Typography>
            )}

            {isState('test') && (
              <Fragment>
                <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                  Your app is currently available for testing.
                </Typography>

                <ShareURL url={`https://homey.app/a/${app.id}/test/`} /><br /><br />
              </Fragment>
            )}

            {isState('in_review') && (
              <Fragment>
                <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                  Your app is currently under review. This process may take up to two weeks.
                </Typography>

                <ShareURL url={`https://homey.app/a/${app.id}/test/`} />

                {userIsReviewer && ReviewerTools}
                {SubmissionComments}
              </Fragment>
            )}

            {isState('reviewed_rejected') && (
              <Fragment>
                <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                  Your app has been rejected.
                </Typography>

                <ShareURL url={`https://homey.app/a/${app.id}/test/`} />

                {userIsReviewer && ReviewerTools}
                {SubmissionComments}
              </Fragment>
            )}

            {isState('reviewed_approved') && (
              <Fragment>
                <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                  Congratulations, your app has been approved!
                  When you are ready, release it to live.
                </Typography>

                <ShareURL url={`https://homey.app/a/${app.id}/test/`} />

                {SubmissionComments}
              </Fragment>
            )}

            {isState('live') && (
              <Fragment>
                <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                  Your app is live.
                </Typography>

                <ShareURL url={`https://homey.app/a/${app.id}/`} />

                {SubmissionComments}
              </Fragment>
            )}

            {isState('superseded') && (
              <Fragment>
                <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                  Your app has been superseded by a newer version.
                </Typography>

                {SubmissionComments}
              </Fragment>
            )}

            <center>
              {buttons.map((button, i) => {
                return (
                  <span
                    key={i}
                    style={{
                      margin: '0 2px',
                    }}
                  >
                    {button}
                  </span>
                );
              })}
            </center>

            {build.archiveUrl && (
              <ShareURL url={build.archiveUrl} title="Archive URL" subtitle="This is the app's source code." />
            )}
          </Card>
        )}

        <BuildDiff
          app={app}
          build={build}
          liveBuild={liveBuild}
        />

      </Page>
    );
  }
}

class ShareURL extends Component {

  render() {
    return (
      <Grid container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <TextField
            value={this.props.url}
            margin="normal"
            label={this.props.title || 'URL to your app'}
            helperText={this.props.subtitle || 'Share this URL to your users.'}
            style={{
              width: 400,
            }}
          />
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => window.open(this.props.url)}
          >
            <OpenInNewIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

}

export default withSnackbar(withMessages(withApi(withUser(withApps(PageAppsAppBuild)))));
